import React, { useState, useEffect } from "react";
import ImagePickerModal from "../components/ImagePickerModal";
import AdminNavBar from '../components/AdminNavBar';
import { Quote } from "../types/types"; 
import Layout from "../components/Layout";

export default function ManageQuote() {
  const [quote, setQuote] = useState<Quote | null>(null); 
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false); 

  useEffect(() => {
    fetchQuote();
  }, []);

  const fetchQuote = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/quotes`);
      if (!response.ok) throw new Error('Failed to fetch quotes');
      const data: Quote[] = await response.json();
      
      if (data.length > 0) {
        setQuote(data[0]);
      } else {
        setQuote(null);
      }
    } catch (error) {
      console.error("Failed to fetch quote:", error);
    }
  };

  const handleSaveQuote = () => {
    if (quote) {
      console.log("Quote before saving:", quote);
      const url = isCreating ? `${process.env.REACT_APP_API_URL}/api/quotes/` : `${process.env.REACT_APP_API_URL}/api/quotes/${quote.id}`;
      const method = isCreating ? "POST" : "PUT";
      
      fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(quote),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to save or update quote');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Quote saved or updated successfully!');
          setQuote(data);
          setIsCreating(false);
        })
        .catch((error) => {
          console.error('Failed to save or update quote:', error);
        });
    }
  };

  const handleImageSelect = (imageId: number, imageUrl: string) => {
    if (quote) {
      const updatedQuote = {
        ...quote,
        imageUrl: imageUrl,
      };
  
      setQuote(updatedQuote);
  
      fetch(`${process.env.REACT_APP_API_URL}/api/quotes/${quote.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedQuote),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to save image URL');
          }
          return response.json();
        })
        .then((updatedQuoteFromBackend) => {
          console.log("Updated quote from server:", updatedQuoteFromBackend);
          setQuote(updatedQuoteFromBackend);
        })
        .catch((error) => {
          console.error('Error while saving image URL:', error);
        });
    }
  };

  const handleOpenImagePicker = () => {
    setIsImagePickerOpen(true);
  };

  const handleToggleTextColor = () => {
    if (quote) {
      const newColor = quote.textColor === "black" ? "white" : "black";
      setQuote({ ...quote, textColor: newColor });
    }
  };

  return (
    <Layout>
      <AdminNavBar />
      <div className="pt-[110px] min-h-screen bg-fractal-pattern p-8 relative">
        <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">Manage Quote</h1>

        {quote ? (
          <div className="space-y-4 p-6 bg-white rounded-lg shadow-lg flex flex-col items-center w-full max-w-3xl mx-auto">
            <input
              type="text"
              value={quote.text}
              maxLength={250} // Set a maximum limit for quote length
              onChange={(e) => setQuote({ ...quote, text: e.target.value })}
              className="text-lg italic mb-2 border-2 border-blue-500 rounded-lg p-4 w-full bg-gray-100 focus:bg-white focus:outline-none focus:border-blue-700 transition duration-300"
              placeholder="Enter quote text here (max 250 characters)..."
            />
            <input
              type="text"
              value={quote.author}
              maxLength={50} // Set a reasonable limit for author length, e.g., 50 characters
              onChange={(e) => setQuote({ ...quote, author: e.target.value })}
              className="text-sm text-gray-700 mb-4 border-2 border-blue-500 rounded-lg p-4 w-full bg-gray-100 focus:bg-white focus:outline-none focus:border-blue-700 transition duration-300"
              placeholder="Enter author's name here (max 50 characters)..."
            />


            {quote.imageUrl && (
              <img
                src={quote.imageUrl}
                alt={`For quote by ${quote.author}`}
                className="w-64 h-32 object-cover mb-4 rounded-lg border border-gray-300 shadow-md"
              />
            )}

            {/* Toggle Button for Text Color */}
            <div className="mt-4">
              <label className="mr-4 text-lg font-semibold text-gray-700">Text Color:</label>
              <button
                onClick={handleToggleTextColor}
                className={`px-6 py-3 rounded-full transition duration-300 ${quote.textColor === "black" ? "bg-black text-white hover:bg-gray-800" : "bg-white text-black hover:bg-gray-200 border border-black"}`}
              >
                {quote.textColor === "black" ? "Change to White Text" : "Change to Black Text"}
              </button>
            </div>
            <button
              onClick={handleOpenImagePicker}
              className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-3 px-6 rounded-lg mt-4 transition duration-300"
            >
              {quote.imageUrl ? "Change Image" : "Add Image"}
            </button>

            <button
              className="mt-6 bg-green-600 hover:bg-green-800 text-white px-6 py-3 rounded-lg font-semibold transition duration-300"
              onClick={handleSaveQuote}
            >
              {isCreating ? "Create Quote" : "Save Quote"}
            </button>
          </div>
        ) : (
          <div className="space-y-6 p-6 bg-white rounded-lg shadow-lg flex flex-col items-center w-full max-w-3xl mx-auto">
            <p className="text-lg text-gray-700">No quote available. Please create a new quote.</p>
            {isCreating ? (
              <>
                <input
                  type="text"
                  placeholder="Quote text"
                  onChange={(e) => setQuote({ text: e.target.value, author: "", imageUrl: "", textColor: "black", id: 0 })}
                  className="text-lg italic mb-2 border-2 border-blue-500 rounded-lg p-4 w-full bg-gray-100 focus:bg-white focus:outline-none focus:border-blue-700 transition duration-300"
                />
                <input
                  type="text"
                  placeholder="Author"
                  onChange={(e) => setQuote((prev) => ({ ...prev!, author: e.target.value }))}
                  className="text-sm text-gray-700 mb-4 border-2 border-blue-500 rounded-lg p-4 w-full bg-gray-100 focus:bg-white focus:outline-none focus:border-blue-700 transition duration-300"
                />
                <button
                  className="bg-green-500 hover:bg-green-700 text-white px-6 py-3 rounded-lg font-semibold transition duration-300"
                  onClick={handleSaveQuote}
                >
                  Save New Quote
                </button>
              </>
            ) : (
              <button
                className="bg-blue-600 hover:bg-blue-800 text-white px-6 py-3 rounded-lg font-semibold transition duration-300"
                onClick={() => setIsCreating(true)}
              >
                Create New Quote
              </button>
            )}
          </div>
        )}

        {isImagePickerOpen && quote && (
          <ImagePickerModal
            isOpen={isImagePickerOpen}
            onClose={() => setIsImagePickerOpen(false)}
            onSelectImage={handleImageSelect}
            activeSection={quote}
          />
        )}
      </div>
    </Layout>
  );
}
