import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import AdminDropdownMenu from './AdminDropdownMenu'; // Import the AdminDropdownMenu

const AdminNavBar = () => {
  const navigate = useNavigate();
  const [isTransparent, setIsTransparent] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const hamburgerRef = useRef<HTMLButtonElement | null>(null); // Ref for hamburger button

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsTransparent(false);
    } else {
      setIsTransparent(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSignOut = () => {
    localStorage.removeItem('token'); // Clears the authentication token
    navigate('/login'); // Redirect to the login page
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    }
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <>
      <div
        className={`fixed top-[60px] left-0 right-0 z-50 transition duration-300 ease-in-out ${
          isTransparent ? 'bg-transparent' : 'bg-gray-900 shadow-md'
        } p-4 flex justify-between items-center`}
        style={{ height: '50px' }}
      >
        {/* Back to Dashboard Link */}
        <Link
          to="/admin"
          className="text-white font-bold hover:text-gray-300 transition duration-200"
        >
          Ridgetop Admin
        </Link>

        {/* Hamburger Icon */}
        <button
          ref={hamburgerRef}
          onClick={toggleDropdown}
          className="text-white text-3xl focus:outline-none transition duration-300 ease-in-out transform hover:scale-110 relative"
        >
          {isDropdownOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute z-50 mt-2 right-4 bg-gray-900 text-white rounded-md shadow-lg p-4 w-[200px]"
          style={{
            position: 'absolute',
            top: hamburgerRef.current
              ? hamburgerRef.current.getBoundingClientRect().bottom + window.scrollY
              : 0,
            left: hamburgerRef.current
              ? hamburgerRef.current.getBoundingClientRect().left - 160
              : 0, // Adjust the left position to align nicely with the button
          }}
        >
          <AdminDropdownMenu onSignOut={handleSignOut} />
        </div>
      )}
    </>
  );
};

export default AdminNavBar;
