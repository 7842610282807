import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../components/Layout';

export default function Register() {
    const [password, setPassword] = useState('');
    const [validToken, setValidToken] = useState<boolean | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    // Extract token from URL
    const token = new URLSearchParams(location.search).get('token');

    useEffect(() => {
        if (!token) {
            // If no token is present, mark as invalid
            setValidToken(false);
            return;
        }

        // Verify token validity (could be done via backend or just simple validation)
        fetch(`${process.env.REACT_APP_API_URL}/api/invite/verify`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token }),
        })
            .then((response) => {
                if (response.ok) {
                    setValidToken(true); // Token is valid
                } else {
                    setValidToken(false); // Token is invalid
                    toast.error("The invitation link is invalid or has expired.");
                }
            })
            .catch(() => {
                setValidToken(false); // Error occurred, assume token is invalid
                toast.error("An error occurred while verifying the invitation link.");
            });
    }, [token]);

    const handleRegister = async () => {
        if (!password) {
            toast.error("Password is required!");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/invite/accept`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, password }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Registration failed');
            }

            // Show success toast
            toast.success("Registration successful! Redirecting to login...");

            // Redirect to login page after a short delay to allow the user to see the notification
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error: any) {
            // Show error toast with the server response error message
            toast.error(error.message);
        }
    };

    if (validToken === null) {
        // While verifying token, you could show a loading message
        return <div className="min-h-screen flex items-center justify-center bg-gray-100"><p className="text-xl text-gray-700">Loading...</p></div>;
    }

    if (validToken === false) {
        // If token is invalid or missing, show the error message
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-100 p-6">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center">
                    <h1 className="text-2xl font-bold mb-4 text-red-600">Invalid Invitation</h1>
                    <p className="text-gray-600">Sorry, you need a valid invitation to set up an account.</p>
                    <ToastContainer />
                </div>
            </div>
        );
    }

    return (
        <Layout>
        <div className="min-h-screen flex items-center justify-center bg-gray-100 p-6">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">Register</h1>
                <div className="mb-4">
                    <input
                        type="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <button
                    onClick={handleRegister}
                    className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                    Register
                </button>
                <ToastContainer />
            </div>
        </div>
        </Layout>
    );
}
