import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';

type BlogPost = {
  id: number;
  title: string;
  content: string;
  published: boolean;
  createdAt: string;
  author: string;
};

export default function BlogPostPage() {
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (id) {
      fetchBlogPost(id);
    }
  }, [id]);

  const fetchBlogPost = async (postId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/blog/${postId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch blog post');
      }
      const data = await response.json();
      setPost(data);
    } catch (error) {
      console.error('Error fetching blog post:', error);
      setError('Failed to load blog post.');
    }
  };

  return (
    <Layout>
      {/* Apply fractal-pattern-4 as the background for the entire section */}
      <div className="min-h-screen bg-fractal-pattern-4 bg-cover bg-fixed p-6">
        {/* White card with shadow to display blog content on top of the fractal background */}
        <div className="max-w-5xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          {error && (
            <div className="bg-red-100 text-red-700 p-4 mb-6 rounded">
              {error}
            </div>
          )}
          {post ? (
            <>
              <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
              <p className="text-gray-500 mb-2 italic">by {post.author || 'Anonymous'}</p> {/* Display author */}
              <p className="text-gray-600 mb-4">
                Published on: {new Date(post.createdAt).toLocaleDateString()}
              </p>
              <div className="text-lg leading-relaxed text-gray-800 whitespace-pre-line">
                {post.content}
              </div>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </Layout>
  );
}
