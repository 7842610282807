import React, { useState, useEffect } from "react";
import { Section, Quote } from "../types/types"; // Import both Section and Quote types

// Update props to be more flexible
interface ImagePickerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectImage: (imageId: number, imageUrl: string) => void;
  activeSection: Section | Quote | null;  // Allow activeSection to be of type Section or Quote
}

const ImagePickerModal: React.FC<ImagePickerModalProps> = ({
  isOpen,
  onClose,
  onSelectImage,
  activeSection,
}) => {
  const [images, setImages] = useState<{ id: number, url: string }[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Fetch images from the backend whenever the modal opens
  useEffect(() => {
    if (isOpen) {
      fetch(`${process.env.REACT_APP_API_URL}/api/images/unassigned`)
        .then(response => response.json())
        .then(data => {
          const imageList = data.images.map((image: { id: number; imageUrl: string }) => ({
            id: image.id,
            url: image.imageUrl
          }));
          setImages(imageList);
        })
        .catch(error => console.error("Error fetching images:", error));
    }
  }, [isOpen]);

  // Handle file input change
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!file) return;

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('file', file);

      const uploadResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/upload`, {
        method: 'POST',
        body: formData,
      });

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload image');
      }

      const { file: uploadedFile } = await uploadResponse.json();
      const { imageUrl } = uploadedFile;

      onSelectImage(uploadedFile.id, imageUrl); // Pass the image info to the parent component
      setImages([...images, { id: uploadedFile.id, url: imageUrl }]); // Update local state
      setFile(null);
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setErrorMessage("Image upload failed");
      setIsLoading(false);
    }
  };

// Handle selecting an image from the list in ImagePickerModal
const handleImageSelect = (imageId: number, imageUrl: string) => {
  console.log("Image selected: ", imageId, imageUrl); // Logging to confirm image selection
  onSelectImage(imageId, imageUrl); // Inform parent component which image was selected
  onClose(); // Close the modal after selection
};


  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Select or Upload Image</h2>

            <input type="file" onChange={handleFileChange} className="mb-4" />
            <button onClick={handleUpload} className="bg-blue-500 text-white px-4 py-2 rounded mb-4">
              Upload
            </button>

            {isLoading && <p>Uploading...</p>}
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}

            <div>
              <h3 className="text-xl font-bold mb-2">Existing Images</h3>
              {images.length > 0 ? (
                <div className="grid grid-cols-3 gap-4">
                  {images.map(({ id, url }) => (
                    <img
                      key={id}
                      src={url}
                      alt=""
                      className="w-24 h-24 object-cover cursor-pointer"
                      onClick={() => handleImageSelect(id, url)} // Pass image ID and URL to parent component
                    />
                  ))}
                </div>
              ) : (
                <p>No images found.</p>
              )}
            </div>

            <button onClick={onClose} className="mt-4 bg-red-500 text-white px-4 py-2 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImagePickerModal;
