import React, { useState } from 'react';
import Layout from '../components/Layout';
import AdminNavBar from '../components/AdminNavBar';

export default function InviteAdmin() {
    const [email, setEmail] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    const handleInvite = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found in localStorage');
            return;
        }
    
        console.log("Sending token:", token); // Debugging log
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/invite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`.trim(), // Proper formatting with Bearer and trim
                },
                body: JSON.stringify({ email }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to send invite');
            }
    
            setStatusMessage('Invitation sent successfully!');
        } catch (error) {
            console.error("Error sending invite:", error); // Log any errors
            setStatusMessage('Failed to send invitation');
        }
    };
    

    return (
        <Layout>
            <AdminNavBar />
            <div className="min-h-screen bg-fractal-pattern-2 bg-cover bg-fixed flex flex-col items-center justify-center p-10">
                <div className="bg-white max-w-md w-full p-8 rounded-lg shadow-lg">
                    <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-6">Invite New Admin</h1>
                    <input
                        type="email"
                        placeholder="Enter email to invite"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-4 border border-gray-300 rounded-lg mb-6 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                    <button
                        onClick={handleInvite}
                        className="w-full bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-3 rounded-lg transition duration-300"
                    >
                        Invite
                    </button>
                    {statusMessage && (
                        <p className={`mt-6 text-center text-lg font-semibold ${statusMessage.includes('successfully') ? 'text-green-600' : 'text-red-600'}`}>
                            {statusMessage}
                        </p>
                    )}
                </div>
            </div>
        </Layout>
    );
}
