
export default function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-10">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 items-start">
        
        {/* Left Column: Company Information */}
        <div className="text-sm">
          <p className="font-bold text-lg mb-2">Ridgetop Botanicals &copy; 2024</p>
          <p>Rutland, VT</p>
          <p>Email: <a href="mailto:ridgetopfarmvtllc@gmail.com" className="hover:text-blue-300">ridgetopfarmvtllc@gmail.com</a></p>
        </div>

        {/* Center Column: Developer Information */}
        <div className="text-center text-gray-400 text-xs mt-6 md:mt-0">
          <p>Developed by <span className="font-bold">Uncle Fester's Nephew</span></p>
        </div>

        {/* Right Column: Social Media Icons */}
        {/* Uncomment this block when social media is ready */}
        {/*
        <div className="flex justify-center md:justify-end space-x-4">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
            <FaFacebook size={24} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400">
            <FaTwitter size={24} />
          </a>
          <a href="https://www.instagram.com/mdavis3987/" target="_blank" rel="noopener noreferrer" className="hover:text-pink-500">
            <FaInstagram size={24} />
          </a>
        </div>
        */}

      </div>
    </footer>
  );
}