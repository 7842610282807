import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

// Define the structure for the BlogPost type
type BlogPost = {
  id: number;
  title: string;
  content: string;
  published: boolean;
  createdAt: string;
  author: string;
};

export default function Blog() {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  // Fetch all published blog posts from the API
  const fetchBlogPosts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/blog`);
      if (!response.ok) {
        throw new Error('Failed to fetch blog posts');
      }
      const data = await response.json();
      setPosts(data.filter((post: BlogPost) => post.published));
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      setError('Failed to load blog posts.');
    }
  };

  return (
    <Layout>
      {/* Use fractal pattern 3 as the background */}
      <div className="min-h-screen bg-fractal-pattern-2 bg-cover bg-fixed py-10 px-5">
        <Parallax translateY={[-20, 20]} className="max-w-7xl mx-auto">
          <h1 className="text-5xl font-extrabold mb-16 text-center text-gray-800">
            Ridgetop Botanicals Blog
          </h1>
        </Parallax>

        {/* Display an error message if any */}
        {error && (
          <div className="bg-red-100 text-red-700 p-4 mb-6 rounded">
            {error}
          </div>
        )}

        {/* Display "Coming Soon" if there are no blog posts */}
        {posts.length === 0 ? (
          <Parallax translateY={[10, -10]} className="text-center">
            <p className="text-3xl text-gray-600 italic">
              Coming Soon
            </p>
          </Parallax>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {posts.map((post) => (
              <Parallax key={post.id} translateY={[10, -10]} className="">
                <div
                  className="bg-white rounded-lg shadow-md overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl bg-wiggle-frame bg-cover bg-center"
                >
                  <div className="p-6">
                    <h2 className="text-3xl font-bold mb-4 text-gray-800">
                      {post.title}
                    </h2>
                    <p className="text-gray-500 mb-2 italic">
                      by {post.author || 'Anonymous'}
                    </p>
                    <p className="text-gray-700 mb-4">
                      {post.content.length > 200
                        ? `${post.content.substring(0, 200)}...`
                        : post.content}
                    </p>
                    <Link
                      to={`/blog/${post.id}`}
                      className="text-blue-600 hover:text-blue-800 font-semibold"
                    >
                      Read More
                    </Link>
                  </div>
                  <div className="bg-gray-100 bg-opacity-90 p-4 text-sm text-gray-600"> {/* Reduced opacity for the background */}
                    Posted on: {new Date(post.createdAt).toLocaleDateString()}
                  </div>
                </div>
              </Parallax>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
}
