// src/components/ProtectedRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const token = localStorage.getItem('token'); // Adjust as needed

  if (!token) {
    // Redirect to login if there's no token
    return <Navigate to="/login" />;
  }

  // Return the children (admin dashboard) if the user is authenticated
  return children;
};

export default ProtectedRoute;
