import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';

// Define the PDF type
type PDF = {
  id: number;
  title: string;
  description: string;
  pdfUrl: string;
};

const TestResults = () => {
  const [pdfs, setPdfs] = useState<PDF[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPdfs, setFilteredPdfs] = useState<PDF[]>([]);

  useEffect(() => {
    const fetchPDFs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/manage/`);
        const data = await response.json();
        setPdfs(Array.isArray(data) ? data : []);
        setFilteredPdfs(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Error fetching PDFs:", error);
      }
    };

    fetchPDFs();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFilteredPdfs(
      pdfs.filter(
        (pdf) =>
          pdf.title.toLowerCase().includes(value.toLowerCase()) ||
          pdf.description.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  return (
    <Layout>
      {/* Main container for TestResults page */}
      <div className="bg-fractal-pattern-2 min-h-screen bg-cover bg-fixed p-8 pt-16 md:pt-24">

        {/* Adding sufficient top padding to ensure content is visible below the header */}
        <div className="container mx-auto p-6 bg-white bg-opacity-95 rounded-lg shadow-lg bg-weed-leaf bg-cover bg-center">
          {/* COA Title */}
          <h1 className="text-4xl font-bold text-center mb-8 text-gray-900">
            Certificates of Analysis (C.O.A.)
          </h1>

          {/* Search Input */}
          <div className="mb-8 w-full max-w-lg mx-auto">
            <input
              type="text"
              placeholder="Search by title or description..."
              className="border border-gray-300 p-4 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-green-500"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          {/* Render PDF Cards */}
          {filteredPdfs.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredPdfs.map((pdf) => (
                <div key={pdf.id} className="bg-white p-6 rounded-lg shadow-lg">
                  <h2 className="font-semibold text-xl mb-4 text-gray-800">
                    {pdf.title}
                  </h2>
                  <p className="text-gray-600 mb-6">{pdf.description}</p>

                  {/* Display PDF using an iframe */}
                  <iframe
                    src={pdf.pdfUrl}
                    width="100%"
                    height="400px"
                    style={{ border: "1px solid #ccc" }}
                    title={pdf.title}
                  ></iframe>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500">No test results available.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default TestResults;
