import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

export default function Header() {
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsHeaderTransparent(false);
      } else {
        setIsHeaderTransparent(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition duration-300 ease-in-out ${
        isHeaderTransparent ? 'bg-transparent text-white' : 'bg-gray-800 text-white'
      }`}
      style={{ height: '60px' }}
    >
      <div className="container mx-auto flex items-center justify-between p-4">
        {/* Business Name */}
        <div className="text-2xl font-bold whitespace-nowrap">
          Ridgetop Botanicals
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="sm:hidden">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-white focus:outline-none z-50"
          >
            {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>

        {/* Navigation Links (full links only shown at `sm` or wider) */}
        <nav className="hidden sm:flex space-x-4">
          <ul className="flex space-x-4">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "text-yellow-300 font-semibold" : "hover:text-yellow-300"
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/test-results"
                className={({ isActive }) =>
                  isActive ? "text-yellow-300 font-semibold" : "hover:text-yellow-300"
                }
              >
                COA
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/blog"
                className={({ isActive }) =>
                  isActive ? "text-yellow-300 font-semibold" : "hover:text-yellow-300"
                }
              >
                Blog
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  isActive ? "text-yellow-300 font-semibold" : "hover:text-yellow-300"
                }
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>

      {/* Mobile Navigation Menu */}
      {isMobileMenuOpen && (
        <div className="fixed top-0 right-0 w-64 bg-gray-800 text-white p-6 rounded-lg shadow-lg mt-[60px] z-40 sm:hidden">
          <ul className="flex flex-col space-y-4">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "text-yellow-300 font-semibold" : "hover:text-yellow-300"
                }
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/test-results"
                className={({ isActive }) =>
                  isActive ? "text-yellow-300 font-semibold" : "hover:text-yellow-300"
                }
                onClick={() => setIsMobileMenuOpen(false)}
              >
                COA
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/blog"
                className={({ isActive }) =>
                  isActive ? "text-yellow-300 font-semibold" : "hover:text-yellow-300"
                }
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Blog
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  isActive ? "text-yellow-300 font-semibold" : "hover:text-yellow-300"
                }
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      )}

      {/* Overlay for Mobile Menu */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 sm:hidden z-30"
          onClick={() => setIsMobileMenuOpen(false)}
        ></div>
      )}
    </header>
  );
}
