import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import AdminNavBar from '../components/AdminNavBar';

// Define the structure for BlogPost
type BlogPost = {
  id: number;
  title: string;
  content: string;
  published: boolean;
  author: string; // Add the author field
};

export default function ManageBlog() {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [error, setError] = useState<string | null>(null);

  // State for creating a new or editing an existing blog post
  const [editingPost, setEditingPost] = useState<BlogPost | null>(null);
  const [newPost, setNewPost] = useState<BlogPost>({
    id: 0,
    title: '',
    content: '',
    published: false,
    author: '', // Add initial value for author
  });

  // State for expanded blog posts (track which blog posts are expanded)
  const [expandedPosts, setExpandedPosts] = useState<Set<number>>(new Set());

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  // Function to fetch all blog posts
  const fetchBlogPosts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/blog`);
      if (!response.ok) {
        throw new Error('Failed to fetch blog posts');
      }
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      setError('Failed to load blog posts.');
    }
  };

  // Function to handle adding or updating a blog post
  const handleSavePost = async () => {
    try {
      const method = editingPost ? 'PUT' : 'POST';
      const url = editingPost
        ? `${process.env.REACT_APP_API_URL}/api/blog/${editingPost.id}`
        : `${process.env.REACT_APP_API_URL}/api/blog`;

      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newPost),
      });

      if (!response.ok) {
        throw new Error('Failed to save blog post');
      }

      fetchBlogPosts(); // Refresh the list after saving
      // Clear form after submission
      setNewPost({ id: 0, title: '', content: '', published: false, author: '' });
      setEditingPost(null); // Reset editing state
    } catch (error) {
      console.error('Error saving blog post:', error);
      setError('Failed to save blog post.');
    }
  };

  // Function to handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewPost({ ...newPost, [name]: value });
  };

  // Function to handle editing a blog post
  const handleEditPost = (post: BlogPost) => {
    setEditingPost(post);
    setNewPost(post); // Populate form with the selected post
  };

  // Function to handle deleting a blog post
  const handleDeletePost = async (id: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/blog/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete blog post');
      }
      fetchBlogPosts(); // Refresh the list after deleting
    } catch (error) {
      console.error('Error deleting blog post:', error);
      setError('Failed to delete blog post.');
    }
  };

  // Function to toggle the published state of a blog post
  const togglePublished = async (id: number, published: boolean) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/blog/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ published: !published }),
      });
      fetchBlogPosts(); // Refresh the blog posts list
    } catch (error) {
      console.error('Error toggling published state:', error);
      setError('Failed to update post.');
    }
  };

  // Function to toggle the expanded state of a blog post
  const toggleExpandedPost = (id: number) => {
    setExpandedPosts(prev => {
      const updatedExpandedPosts = new Set(prev);
      if (updatedExpandedPosts.has(id)) {
        updatedExpandedPosts.delete(id);
      } else {
        updatedExpandedPosts.add(id);
      }
      return updatedExpandedPosts;
    });
  };

  return (
    <Layout>
      <AdminNavBar />
      <div className="min-h-screen bg-fractal-pattern-2 bg-cover bg-fixed p-10">
        <div className="max-w-5xl mx-auto bg-white p-8 rounded-lg shadow-xl">
          <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-10">Manage Blog Posts</h1>

          {error && (
            <div className="bg-red-100 text-red-700 p-4 mb-6 rounded-lg shadow-md">
              {error}
            </div>
          )}

          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4">{editingPost ? 'Edit Blog Post' : 'Create New Blog Post'}</h2>
            <input
              type="text"
              name="title"
              value={newPost.title}
              onChange={handleInputChange}
              placeholder="Blog Title"
              className="w-full p-4 mb-4 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              name="author"
              value={newPost.author}
              onChange={handleInputChange}
              placeholder="Author Name"
              className="w-full p-4 mb-4 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <textarea
              name="content"
              value={newPost.content}
              onChange={handleInputChange}
              placeholder="Blog Content"
              className="w-full p-4 mb-4 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-64 resize-none"
            ></textarea>
            <div className="flex space-x-4">
              <button
                onClick={handleSavePost}
                className="bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold py-3 px-6 rounded-lg shadow-md hover:from-blue-600 hover:to-blue-800 transition duration-300"
              >
                {editingPost ? 'Save Changes' : 'Add Blog Post'}
              </button>
              {editingPost && (
                <button
                  onClick={() => {
                    setEditingPost(null);
                    setNewPost({ id: 0, title: '', content: '', published: false, author: '' });
                  }}
                  className="bg-gradient-to-r from-red-500 to-red-700 text-white font-bold py-3 px-6 rounded-lg shadow-md hover:from-red-600 hover:to-red-800 transition duration-300"
                >
                  Cancel Edit
                </button>
              )}
            </div>
          </div>

          {/* Blog posts list */}
          {posts.length === 0 ? (
            <div className="text-center text-lg text-gray-600 mt-10">
              <p>No blog posts available at this time.</p>
            </div>
          ) : (
            <ul className="space-y-6">
              {posts.map(post => (
                <li key={post.id} className="bg-gray-50 p-6 rounded-lg shadow-md">
                  <h2 className="text-2xl font-bold mb-2 text-gray-800">{post.title}</h2>
                  <p className="text-gray-600 italic mb-2">by {post.author}</p>
                  <button
                    onClick={() => toggleExpandedPost(post.id)}
                    className="text-blue-500 underline mb-4 inline-block focus:outline-none"
                  >
                    {expandedPosts.has(post.id) ? 'Show Less' : 'Show More'}
                  </button>
                  <p className="text-gray-700 mb-4">
                    {expandedPosts.has(post.id) ? post.content : `${post.content.substring(0, 200)}...`}
                  </p>
                  <div className="flex space-x-4">
                    <button
                      onClick={() => togglePublished(post.id, post.published)}
                      className={`${
                        post.published ? 'bg-green-500' : 'bg-yellow-500'
                      } text-white px-6 py-2 rounded-lg shadow-md hover:bg-opacity-90 transition duration-300`}
                    >
                      {post.published ? 'Unpublish' : 'Publish'}
                    </button>
                    <button
                      onClick={() => handleEditPost(post)}
                      className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-600 transition duration-300"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeletePost(post.id)}
                      className="bg-red-500 text-white px-6 py-2 rounded-lg shadow-md hover:bg-red-600 transition duration-300"
                    >
                      Delete
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Layout>
  );
}
