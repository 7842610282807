import React, { useState, useEffect } from "react";
import { DndContext, closestCenter, DragOverlay } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortablePDFItem from "../components/SortablePDFItem";
import PDFPickerModal from "../components/PDFPickerModal";
import AdminNavBar from '../components/AdminNavBar';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Layout from "../components/Layout";

// Define the structure for each COA entry
interface CoaEntry {
  id: number;
  title: string;
  description: string;
  pdfUrl: string;
}

export default function ManageCoas() {
  const [coas, setCoas] = useState<CoaEntry[]>([]);
  const [isPDFUploaderOpen, setIsPDFUploaderOpen] = useState(false);
  const [activeCoa, setActiveCoa] = useState<CoaEntry | null>(null);

  useEffect(() => {
    fetchCoas();
  }, []);

  // Fetch COAs from the backend
  const fetchCoas = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/manage`);
      if (response.ok) {
        const data: CoaEntry[] = await response.json();
        setCoas(data);
      } else {
        throw new Error('Failed to fetch COAs');
      }
    } catch (error) {
      console.error("Failed to fetch COAs:", error);
    }
  };

  // Save a COA entry (either create or update)
  const handleSaveCoa = async (updatedCoa: CoaEntry) => {
    const endpoint = updatedCoa.id
      ? `${process.env.REACT_APP_API_URL}/api/manage/update/${updatedCoa.id}`
      : `${process.env.REACT_APP_API_URL}/api/manage/create`;

    try {
      const response = await fetch(endpoint, {
        method: updatedCoa.id ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedCoa),
      });

      if (!response.ok) {
        throw new Error(`Failed to save COA: ${response.statusText}`);
      }

      // Fetch the updated list of COAs after saving
      await fetchCoas();
      toast.success("COA saved successfully!");
    } catch (error) {
      toast.error("Failed to save COA");
      console.error('Failed to save COA:', error);
    }
  };

  // Delete a COA entry
  const handleDeleteCoa = async (id: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/manage/delete/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        setCoas(coas.filter(coa => coa.id !== id));
        toast.success("COA deleted successfully!");
      } else {
        throw new Error('Failed to delete COA');
      }
    } catch (error) {
      toast.error("Failed to delete COA");
      console.error('Failed to delete COA:', error);
    }
  };

  // Handle drag and drop reordering of COAs
  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    if (!over || !active) return;

    if (active.id !== over.id) {
      const oldIndex = coas.findIndex(coa => coa.id === parseInt(active.id));
      const newIndex = coas.findIndex(coa => coa.id === parseInt(over.id));
      const reorderedCoas = arrayMove(coas, oldIndex, newIndex);
      setCoas(reorderedCoas);

      // Save the new order to the backend
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/manage/update-order`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(reorderedCoas.map((coa, index) => ({ id: coa.id, order: index }))),
        });
        if (response.ok) {
          toast.success("COA order updated successfully!");
        } else {
          throw new Error('Failed to update COA order');
        }
      } catch (error) {
        toast.error("Failed to update COA order on the server.");
        console.error('Failed to update COA order:', error);
      }
    }
  };

  // Handle the opening of the PDF uploader
  const handleOpenPDFUploader = () => {
    setIsPDFUploaderOpen(true);
  };

  return (
    <Layout>
      <AdminNavBar />
      <div className="pt-[110px] min-h-screen bg-fractal-pattern-3 bg-cover bg-fixed p-10 relative">
        <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-10">
          Manage Certificates of Analysis (COAs)
        </h1>

        {/* Upload New COA Button */}
        <div className="text-center mb-8">
          <button
            onClick={() => handleOpenPDFUploader()}
            className="bg-gradient-to-r from-green-400 to-green-600 text-white font-bold py-3 px-8 rounded-lg shadow-md hover:from-green-500 hover:to-green-700 transition duration-300"
          >
            Upload New COA
          </button>
        </div>

        {/* Sortable List of COAs */}
        {coas.length > 0 ? (
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={coas.map((coa) => coa.id.toString())} strategy={verticalListSortingStrategy}>
            <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {coas.map((coa) => (
                <SortablePDFItem
                  key={coa.id.toString()}
                  item={{
                    id: coa.id,
                    title: coa.title,
                    description: coa.description,
                    file: { pdfUrl: coa.pdfUrl },
                  }}
                  onSave={(updatedItem) => handleSaveCoa({
                    id: updatedItem.id,
                    title: updatedItem.title,
                    description: updatedItem.description || "", // Fix type issue by ensuring description is a string
                    pdfUrl: updatedItem.file?.pdfUrl || "",
                  })}
                  onDelete={() => handleDeleteCoa(coa.id)}
                  onTitleChange={(title) =>
                    setCoas(coas.map((s) =>
                      s.id === coa.id ? { ...s, title } : s))}
                  onDescriptionChange={(description) =>
                    setCoas(coas.map((s) =>
                      s.id === coa.id ? { ...s, description } : s))}
                />
              ))}
            </div>

            </SortableContext>

            <DragOverlay>
              {activeCoa && (
                <div className="bg-white p-6 rounded-lg shadow-lg cursor-grabbing">
                  <h2 className="text-xl font-bold">{activeCoa.title}</h2>
                  <p className="text-sm text-gray-600">{activeCoa.description}</p>
                </div>
              )}
            </DragOverlay>
          </DndContext>
        ) : (
          <div className="text-center mt-10 text-gray-600 text-lg">
            <p>No COAs available to display. Please add some data.</p>
          </div>
        )}

        {/* PDF Picker Modal */}
        {isPDFUploaderOpen && (
          <PDFPickerModal
            isOpen={isPDFUploaderOpen}
            onClose={() => setIsPDFUploaderOpen(false)}
            onSelectPDF={(pdfFiles) => {
              pdfFiles.forEach((pdfFile) => {
                const newCoa: CoaEntry = {
                  id: pdfFile.id,
                  title: "Untitled",
                  description: "",
                  pdfUrl: pdfFile.pdfUrl,
                };
                handleSaveCoa(newCoa);
              });
              setIsPDFUploaderOpen(false);
              fetchCoas(); // Fetch updated COAs list after upload
              toast.success("PDFs uploaded and COAs saved successfully!");
            }}
          />
        )}

        {/* Toast Container for Notifications */}
        <ToastContainer position="top-center" autoClose={3000} hideProgressBar={false} />
      </div>
    </Layout>
  );
}
