import React from 'react';
import { Link } from 'react-router-dom';

interface AdminDropdownMenuProps {
  onSignOut: () => void;
}

const AdminDropdownMenu: React.FC<AdminDropdownMenuProps> = ({ onSignOut }) => {
  return (
    <div
      className="w-48 bg-gray-800 text-white rounded-lg shadow-md p-4"
    >
      <nav className="flex flex-col space-y-2">
        <Link
          to="/admin/manage-about-us"
          className="hover:text-yellow-400 transition duration-300"
        >
          Manage About Us
        </Link>
        <Link
          to="/admin/manage-quotes"
          className="hover:text-yellow-400 transition duration-300"
        >
          Manage Quotes
        </Link>
        <Link
          to="/admin/manage-coa"
          className="hover:text-yellow-400 transition duration-300"
        >
          Manage COA/Test Results
        </Link>
        <Link
          to="/admin/contacts"
          className="hover:text-yellow-400 transition duration-300"
        >
          View Visitor Contacts
        </Link>
        <Link
          to="/admin/manage-blog"
          className="hover:text-yellow-400 transition duration-300"
        >
          Manage Blog
        </Link>
        <Link
          to="/admin/invite-admin"
          className="hover:text-yellow-400 transition duration-300"
        >
          Invite Admin
        </Link>
        <button
          onClick={onSignOut}
          className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 rounded-lg mt-4 transition duration-300"
        >
          Sign Out
        </button>
      </nav>
    </div>
  );
};

export default AdminDropdownMenu;
