import React, { useState, useEffect } from "react";

interface PDFPickerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectPDF: (pdfFiles: { id: number; pdfUrl: string }[]) => void; // Updated to accept multiple files with IDs
}

const PDFPickerModal: React.FC<PDFPickerModalProps> = ({
  isOpen,
  onClose,
  onSelectPDF,
}) => {
  const [pdfs, setPDFs] = useState<{ id: number, pdfUrl: string }[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileExists, setFileExists] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      fetch(`${process.env.REACT_APP_API_URL}/api/manage/`)
        .then((response) => response.json())
        .then((data) => {
          const pdfList = data.map((pdf: any) => ({ id: pdf.id, pdfUrl: pdf.pdfUrl }));
          setPDFs(pdfList);
        })
        .catch((error) => {
          console.error("Error fetching PDFs:", error);
          setPDFs([]);
        });
    }
  }, [isOpen]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(Array.from(event.target.files));
      setFileExists(false);
    }
  };

  const handleUpload = async () => {
    if (files.length === 0) return;

    setIsLoading(true);
    setErrorMessage(null);
    const uploadedFiles: { id: number; pdfUrl: string }[] = [];

    try {
      for (const file of files) {
        const formData = new FormData();
        formData.append("file", file);

        const uploadResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/manage/create`, {
          method: "POST",
          body: formData,
        });

        if (uploadResponse.status === 409) {
          setFileExists(true);
          continue; // Skip this file if it already exists
        }

        if (!uploadResponse.ok) throw new Error("Failed to upload PDF");

        // Get the complete file details, including the ID, from the backend response
        const responseData = await uploadResponse.json();
        const uploadedFile = {
          id: responseData.file.id, // Use ID from backend
          pdfUrl: responseData.file.pdfUrl,
        };
        uploadedFiles.push(uploadedFile);
      }

      if (uploadedFiles.length > 0) {
        onSelectPDF(uploadedFiles); // Use the updated function that takes an array of file objects
      }

      setFiles([]);
      setIsLoading(false);
      onClose(); 
    } catch (error) {
      console.error("Error uploading PDF:", error);
      setErrorMessage("PDF upload failed");
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Select or Upload PDF</h2>

            <input 
              type="file" 
              accept=".pdf" 
              multiple 
              onChange={handleFileChange} 
              className="mb-4" 
              disabled={isLoading} 
            />

            <button
              onClick={handleUpload}
              className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
              disabled={isLoading}
            >
              {isLoading ? "Uploading..." : "Upload"}
            </button>

            {fileExists && (
              <div>
                <p className="text-red-500">A PDF with this name already exists. Please choose another file.</p>
                <button
                  onClick={() => setFileExists(false)}
                  className="bg-yellow-500 text-white px-4 py-2 rounded mt-2"
                >
                  Try Again
                </button>
              </div>
            )}

            {errorMessage && <p className="text-red-500">{errorMessage}</p>}

            <div>
              <h3 className="text-xl font-bold mb-2">Existing PDFs</h3>
              {pdfs && pdfs.length > 0 ? (
                <div className="grid grid-cols-3 gap-4">
                  {pdfs.map((pdf) => (
                    <div key={pdf.id}>
                      <a
                        href={pdf.pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        View PDF
                      </a>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No PDFs found.</p>
              )}
            </div>

            <button onClick={onClose} className="mt-4 bg-red-500 text-white px-4 py-2 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFPickerModal;
