import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Admin from './pages/Admin';
import Login from './pages/Login';
import ManageAboutUs from './pages/ManageAboutUs';
import ManageCoas from './pages/ManageCOAPage';
import ProtectedRoute from './components/ProtectedRoute';
import TestResults from './pages/TestResults';
import AgeVerificationModal from './components/AgeVerificationModal';
import ContactUs from './pages/ContactUs';
import AdminContacts from './pages/AdminContacts';
import Blog from './pages/Blog';
import BlogPostPage from './pages/BlogPostPage'; 
import ManageBlog from './pages/ManageBlog'; 
import ManageQuotes from './pages/ManageQuotes';
import InviteAdmin from './pages/InviteAdmin';
import Register from './pages/Register'; 
import { ParallaxProvider } from 'react-scroll-parallax';
import React, { useEffect, useState } from 'react';

function App() {
  const [isVerified, setIsVerified] = useState<boolean>(false);

  // Check if the user has verified their age before
  useEffect(() => {
    const ageVerified = localStorage.getItem('ageVerified');
    if (ageVerified === 'true') {
      setIsVerified(true);
    }
  }, []);

  const handleVerify = () => {
    setIsVerified(true);
  };

  // If user is not verified, show the AgeVerificationModal
  if (!isVerified) {
    return <AgeVerificationModal onVerify={handleVerify} />;
  }

  // Render the rest of the application if age is verified
  return (
    <ParallaxProvider>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/test-results" element={<TestResults />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/register" element={<Register />} /> 
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:id" element={<BlogPostPage />} />
      <Route 
        path="/admin" 
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />
      <Route 
        path="/admin/manage-about-us" 
        element={
          <ProtectedRoute>
            <ManageAboutUs />
          </ProtectedRoute>
        }
      />
      <Route 
        path="/admin/manage-coa" 
        element={
          <ProtectedRoute>
            <ManageCoas />
          </ProtectedRoute>
        }
      />
      <Route 
        path="/admin/contacts" 
        element={
          <ProtectedRoute>
            <AdminContacts />
          </ProtectedRoute>
        }
      />
      <Route 
        path="/admin/manage-blog" 
        element={
          <ProtectedRoute>
            <ManageBlog />
          </ProtectedRoute>
        }
      />
      <Route 
        path="/admin/manage-quotes" // New Route for Managing Quotes
        element={
          <ProtectedRoute>
            <ManageQuotes />
          </ProtectedRoute>
        }
      />
      <Route 
        path="/admin/invite-admin"  // New Invite Admin Route
        element={
          <ProtectedRoute>
            <InviteAdmin />
          </ProtectedRoute>
        }
      />
    </Routes>
    </ParallaxProvider>
  );
}

export default App;
