import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'; // Correctly import Autoplay for v11.x
import 'swiper/css'; // Import Swiper base styles
import 'swiper/css/autoplay'; // Import styles for Autoplay functionality
import type { Swiper as SwiperInstance } from 'swiper'; // Import the Swiper instance type for reference

const ImageCarousel = () => {
  const [images, setImages] = useState<{ imageUrl: string }[]>([]);
  const [error, setError] = useState<string | null>(null);
  const swiperRef = useRef<SwiperInstance | null>(null); // Properly type the Swiper instance

  useEffect(() => {
    // Fetch all the images
    fetch(`${process.env.REACT_APP_API_URL}/api/images/unassigned`) // Adjust this to your endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch images. Please check your server.');
        }
        return response.json();
      })
      .then((data) => {
        if (data.images && data.images.length > 0) {
          setImages(data.images);
          setError(null); // Clear any existing error messages
        } else {
          setError('No images found in the database.');
        }
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
        setError('Error fetching images from the server. Please try again later.');
      });
  }, []);

  return (
    <div className="carousel-container mt-10">
      {error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : (
        <Swiper
          onSwiper={(swiper: SwiperInstance) => { // Add type annotation here
            swiperRef.current = swiper;
          }}
          modules={[Autoplay]} // Include the Autoplay module
          spaceBetween={20}
          slidesPerView={images.length < 3 ? images.length : 3}
          loop={images.length > 3} // Enable loop only if there are enough images
          autoplay={{
            delay: 0, // Start immediately for a continuous scroll
            disableOnInteraction: false, // Allow users to interact without permanently stopping the autoplay
            pauseOnMouseEnter: true, // Pause autoplay when hovering over the carousel
          }}
          speed={4000} // Set a slow speed for continuous smooth scrolling
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image.imageUrl}
                alt={`Carousel slide ${index + 1}`}
                className="w-full h-40 object-cover rounded-lg shadow-md"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default ImageCarousel;
