import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout'; // Assuming you want to reuse the Layout (Header & Footer)
import AdminNavBar from '../components/AdminNavBar'; // Admin navigation for internal admin links
import { CSVLink } from 'react-csv';

// Define types for Contact and Message

type Message = {
  id: number;
  question: string;
  createdAt: string;
};

type Contact = {
  id: number;
  name: string | null;
  email: string;
  createdAt: string;
  messages: Message[];
};

export default function AdminContacts() {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [error, setError] = useState<string | null>(null);

  // Fetch contact submissions when the component mounts
  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`);
      if (!response.ok) {
        throw new Error(`Failed to fetch contacts: ${response.statusText}`);
      }
      const data = await response.json();
      setContacts(data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setError('Failed to load contacts. Please try again later.');
    }
  };

  // Prepare data for CSV export
  const csvHeaders = [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Created At', key: 'createdAt' },
    { label: 'Messages', key: 'messages' },
  ];

  const csvData = contacts.map(contact => ({
    id: contact.id,
    name: contact.name || 'N/A',
    email: contact.email,
    createdAt: new Date(contact.createdAt).toLocaleString(),
    messages: contact.messages.map(message => message.question).join('; '),
  }));

  return (
    <Layout>
      <AdminNavBar />
      <div className="min-h-screen bg-fractal-pattern-3 bg-cover bg-fixed p-10">
        <div className="max-w-5xl mx-auto bg-white p-8 rounded-lg shadow-xl">
          <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-10">Visitor Contacts</h1>

          {error && (
            <div className="bg-red-100 text-red-700 p-4 mb-6 rounded-lg shadow-md">
              {error}
            </div>
          )}

          {/* CSV Export Button */}
          {contacts.length > 0 && (
            <div className="text-right mb-6">
              <CSVLink
                headers={csvHeaders}
                data={csvData}
                filename="visitor_contacts.csv"
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300"
              >
                Download CSV
              </CSVLink>
            </div>
          )}

          {contacts.length === 0 ? (
            <div className="text-center text-lg text-gray-600 mt-10">
              <p>No contacts available at this time.</p>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
                <thead>
                  <tr className="bg-blue-600 text-white">
                    <th className="py-4 px-6 border-b">ID</th>
                    <th className="py-4 px-6 border-b">Name</th>
                    <th className="py-4 px-6 border-b">Email</th>
                    <th className="py-4 px-6 border-b">Messages</th>
                    <th className="py-4 px-6 border-b">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.map((contact, idx) => (
                    <React.Fragment key={contact.id}>
                      <tr className={`${idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition duration-300`}>
                        <td className="py-4 px-6 border-b text-center font-medium text-gray-700">{contact.id}</td>
                        <td className="py-4 px-6 border-b text-center font-medium text-gray-700">{contact.name || "N/A"}</td>
                        <td className="py-4 px-6 border-b text-center font-medium text-gray-700">
                          <a href={`mailto:${contact.email}`} className="text-blue-600 hover:underline">
                            {contact.email}
                          </a>
                        </td>
                        <td className="py-4 px-6 border-b">
                          {contact.messages.length > 0 ? (
                            contact.messages.map((message) => (
                              <div key={message.id} className="mb-4 p-3 bg-gray-100 rounded-lg shadow">
                                <p className="font-semibold text-gray-800">{message.question}</p>
                                <small className="block text-gray-500 mt-1">
                                  {new Date(message.createdAt).toLocaleString()}
                                </small>
                              </div>
                            ))
                          ) : (
                            <p className="text-center text-gray-500">No messages available</p>
                          )}
                        </td>
                        <td className="py-4 px-6 border-b text-center font-medium text-gray-700">
                          {new Date(contact.createdAt).toLocaleString()}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
