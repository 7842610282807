import React, { useState, useEffect } from "react";
import { DndContext, closestCenter, DragOverlay } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortableItem from "../components/SortableItem";
import ImagePickerModal from "../components/ImagePickerModal";
import InstructionModal from "../components/InstructionModal";
import AdminNavBar from '../components/AdminNavBar';
import { Section } from "../types/types";
import Layout from "../components/Layout";

export default function ManageAboutUs() {
  // State to hold all sections data
  const [sections, setSections] = useState<Section[]>([]);
  
  // State variables for managing toast notifications
  const [saveSuccess, setSaveSuccess] = useState<boolean | null>(null);
  const [imageUpdateSuccess, setImageUpdateSuccess] = useState<boolean | null>(null);
  const [createSuccess, setCreateSuccess] = useState<boolean | null>(null);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean | null>(null);

  // State variables for modal visibility and managing active sections
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);
  const [activeSection, setActiveSection] = useState<Section | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  
  // State for new section data input
  const [newSectionData, setNewSectionData] = useState<{ 
    title: string; 
    text: string; 
    imageId: number | null; 
    imageUrl: string | null 
  }>({
    title: "",
    text: "",
    imageId: null,
    imageUrl: null,
  });

  // Fetch sections from the backend when the component mounts
  useEffect(() => {
    fetchSections();
  }, []);

  const fetchSections = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/about-us`);
      const data: Section[] = await response.json();

      if (Array.isArray(data)) {
        setSections(data);
        console.log("Fetched sections:", data);
      }
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    }
  };

  const updateSectionImage = (sectionId: number, newImageUrl: string) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId ? { ...section, file: { ...section.file, imageUrl: newImageUrl } } : section
      )
    );
  };

  const handleSaveSection = async (updatedSection: Section) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/about-us/update`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sectionId: updatedSection.id,
          title: updatedSection.title,
          text: updatedSection.text,
          imageId: updatedSection.imageId || null,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to save section: ${response.statusText}`);
      }

      setSections(sections.map((section) => (section.id === updatedSection.id ? updatedSection : section)));
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(null), 3000); // Clear toast after 3 seconds
    } catch (error) {
      setSaveSuccess(false);
      setTimeout(() => setSaveSuccess(null), 3000); // Clear toast after 3 seconds
      console.error("Failed to save section:", error);
    }
  };

  const handleImageSelect = (imageId: number, imageUrl: string) => {
    if (activeSection) {
      fetch(`${process.env.REACT_APP_API_URL}/api/about-us/save-image-id`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ sectionId: activeSection.id, imageId }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to save image ID");
          }
          return response.json();
        })
        .then(() => {
          updateSectionImage(activeSection.id, imageUrl);
          setIsImagePickerOpen(false);
          setImageUpdateSuccess(true);
          setTimeout(() => setImageUpdateSuccess(null), 3000); // Clear toast after 3 seconds
        })
        .catch((error) => {
          console.error("Failed to save image ID:", error);
          setImageUpdateSuccess(false);
          setTimeout(() => setImageUpdateSuccess(null), 3000); // Clear toast after 3 seconds
        });
    }
  };

  const handleCreateSection = async () => {
    if (!newSectionData.title || !newSectionData.text) {
      setCreateSuccess(false);
      setTimeout(() => setCreateSuccess(null), 3000);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/about-us/create`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newSectionData),
      });

      if (!response.ok) {
        throw new Error(`Failed to create section: ${response.statusText}`);
      }

      const createdSection = await response.json();
      setSections([...sections, createdSection]);
      setIsCreating(false);
      setNewSectionData({ title: "", text: "", imageId: null, imageUrl: null });
      setCreateSuccess(true);
      setTimeout(() => setCreateSuccess(null), 3000);
    } catch (error) {
      setCreateSuccess(false);
      setTimeout(() => setCreateSuccess(null), 3000);
      console.error("Error creating section:", error);
    }
  };

  const handleDeleteSection = async (id: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/about-us/delete`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ sectionId: id }),
      });

      if (response.ok) {
        setSections(sections.filter((section) => section.id !== id));
        setDeleteSuccess(true);
      } else {
        throw new Error("Failed to delete section");
      }
    } catch (error) {
      setDeleteSuccess(false);
      console.error("Failed to delete section:", error);
    } finally {
      setTimeout(() => setDeleteSuccess(null), 3000);
    }
  };

  // Function to handle the opening of the image picker modal
  const handleOpenImagePicker = (section: Section) => {
    setActiveSection(section);
    setIsImagePickerOpen(true);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (!over || !active) return;
  
    if (active.id !== over.id) {
      const oldIndex = sections.findIndex(section => section.id === parseInt(active.id));
      const newIndex = sections.findIndex(section => section.id === parseInt(over.id));
  
      if (oldIndex !== -1 && newIndex !== -1) {
        const reorderedSections = arrayMove(sections, oldIndex, newIndex);
        setSections(reorderedSections);
        console.log('Sections reordered:', reorderedSections);
      }
    }
  };
  

  return (
    <Layout>
      <AdminNavBar />

      {/* Main Container */}
      <div className="pt-[110px] min-h-screen bg-fractal-pattern-3 bg-cover bg-fixed p-10 relative">
        <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-8">Manage About Us</h1>

        {/* Toast Notifications */}
        {saveSuccess && (
          <div className={`fixed bottom-4 right-4 p-4 rounded shadow-lg ${saveSuccess ? 'bg-green-500' : 'bg-red-500'} text-white`}>
            {saveSuccess ? "Section saved successfully!" : "Failed to save the section."}
          </div>
        )}
        {imageUpdateSuccess && (
          <div className={`fixed bottom-4 right-4 p-4 rounded shadow-lg ${imageUpdateSuccess ? 'bg-green-500' : 'bg-red-500'} text-white`}>
            {imageUpdateSuccess ? "Image updated successfully!" : "Failed to update image."}
          </div>
        )}
        {createSuccess && (
          <div className={`fixed bottom-4 right-4 p-4 rounded shadow-lg ${createSuccess ? 'bg-green-500' : 'bg-red-500'} text-white`}>
            {createSuccess ? "New section created successfully!" : "Failed to create section."}
          </div>
        )}
        {deleteSuccess && (
          <div className={`fixed bottom-4 right-4 p-4 rounded shadow-lg ${deleteSuccess ? 'bg-green-500' : 'bg-red-500'} text-white`}>
            {deleteSuccess ? "Section deleted successfully!" : "Failed to delete the section."}
          </div>
        )}

        <InstructionModal />

        {/* Create New Section Button */}
        <div className="text-center mb-6">
          <button
            onClick={() => setIsCreating(true)}
            className="bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold py-2 px-6 rounded-lg shadow-md hover:from-blue-600 hover:to-blue-800 transition duration-300"
          >
            Create New Section
          </button>
        </div>

        {/* Create New Section Form */}
        {isCreating && (
          <div className="mb-8 p-6 bg-white rounded-lg shadow-md max-w-2xl mx-auto">
            <h2 className="text-2xl font-bold text-center mb-4">New Section</h2>
            <input
              type="text"
              placeholder="Title"
              value={newSectionData.title}
              onChange={(e) => setNewSectionData({ ...newSectionData, title: e.target.value })}
              className="mb-4 p-4 border rounded-md w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <textarea
              placeholder="Text"
              value={newSectionData.text}
              onChange={(e) => setNewSectionData({ ...newSectionData, text: e.target.value })}
              className="mb-4 p-4 border rounded-md w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
            />
            <div className="flex justify-between">
              <button
                onClick={handleCreateSection}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-md shadow-md transition duration-300 disabled:bg-gray-300"
                disabled={!newSectionData.title || !newSectionData.text}
              >
                Save Section
              </button>
              <button
                onClick={() => setIsCreating(false)}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-6 rounded-md shadow-md transition duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Sortable List of Sections */}
        {sections.length > 0 ? (
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={sections.map((section) => section.id)} strategy={verticalListSortingStrategy}>
              <div className="space-y-6">
                {sections.map((section) => (
                  <SortableItem
                    key={section.id.toString()}
                    section={section}
                    onSave={handleSaveSection}
                    onDelete={() => handleDeleteSection(section.id)}
                    onImageClick={() => handleOpenImagePicker(section)}
                    onTitleChange={(title) =>
                      setSections(sections.map((s) => (s.id === section.id ? { ...s, title } : s)))
                    }
                    onTextChange={(text) =>
                      setSections(sections.map((s) => (s.id === section.id ? { ...s, text } : s)))
                    }
                  />
                ))}
              </div>
            </SortableContext>
            <DragOverlay>
              {activeSection && (
                <div className="bg-white p-4 rounded-md shadow-lg cursor-grabbing">
                  <h2 className="text-lg font-bold">{activeSection.title}</h2>
                  <p className="text-sm">{activeSection.text}</p>
                </div>
              )}
            </DragOverlay>
          </DndContext>
        ) : (
          <div className="text-center text-lg text-gray-700 mt-8">
            <p>No sections available to display. Please check your data source or API.</p>
          </div>
        )}

        {/* Image Picker Modal */}
        {isImagePickerOpen && activeSection && (
          <ImagePickerModal
            isOpen={isImagePickerOpen}
            onClose={() => setIsImagePickerOpen(false)}
            onSelectImage={handleImageSelect}
            activeSection={activeSection}
          />
        )}
      </div>
    </Layout>
  );
}
