import React, { useState } from 'react';
import Layout from '../components/Layout';

export default function ContactUs() {
  // State to manage form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    question: ''
  });

  // State to handle form submission status
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean | null>(null);
  const [message, setMessage] = useState('');

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Make POST request to backend with full URL
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      console.log("Response status:", response.status);

      if (response.ok) {
        setMessage('Thank you for your message! We will get back to you soon.');
        setSubmissionSuccess(true);
        setFormData({
          name: '',
          email: '',
          question: ''
        });
      } else {
        const errorText = await response.text();
        console.error("Backend responded with an error:", errorText);
        setMessage('Something went wrong. Please try again.');
        setSubmissionSuccess(false);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setMessage('An error occurred. Please try again.');
      setSubmissionSuccess(false);
    } finally {
      // Hide the notification after 3 seconds
      setTimeout(() => {
        setSubmissionSuccess(null);
        setMessage('');
      }, 3000);
    }
  };

  return (
    <Layout>
      {/* Add the fractal background to the page */}
      <div className="bg-fractal-pattern-2 min-h-screen bg-cover bg-fixed p-6 pt-20 flex items-center justify-center">
        {/* Main container with flex to arrange in a row for large screens */}
        <div className="max-w-5xl w-full bg-white bg-opacity-90 rounded-lg shadow-md p-8 grid gap-6 md:grid-cols-2">
          
          {/* Introductory Message */}
          <div className="flex flex-col justify-center border-r-2 border-gray-200 pr-6">
            <h1 className="text-3xl font-bold text-green-900 mb-4">Welcome to Ridgetop Botanicals</h1>
            <p className="text-md text-gray-800 leading-relaxed">
              At Ridgetop Botanicals, we're not just cultivating cannabis; we're cultivating a community built on trust, transparency, and a shared love for nature's wonders. 
              We invite you to follow our journey, learn from our experiences, and be a part of our ever-growing family.
              <br /><br />
              Join us on this extraordinary adventure, and together, let's celebrate the beauty of small-scale, farmer-owned and operated agriculture, and the incredible potential of high terpene, high potency botanicals. 
              Experience Ridgetop Botanicals, where nature meets terpenes, and let the journey begin.
            </p>
          </div>

          {/* Contact Form */}
          <div className="pl-6">
            <h1 className="text-3xl font-bold mb-4 text-center text-green-900">Contact Us</h1>
            <form onSubmit={handleSubmit}>
              {/* Name Input Field */}
              <div className="mb-3">
                <label className="block text-gray-700 mb-1" htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-100"
                />
              </div>
              
              {/* Email Input Field */}
              <div className="mb-3">
                <label className="block text-gray-700 mb-1" htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-100"
                />
              </div>

              {/* Question Textarea Field */}
              <div className="mb-3">
                <label className="block text-gray-700 mb-1" htmlFor="question">Your Question</label>
                <textarea
                  id="question"
                  name="question"
                  value={formData.question}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-100 h-28 resize-none"
                ></textarea>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="bg-green-700 text-white py-2 px-4 rounded hover:bg-green-800 transition duration-200 w-full mt-2"
              >
                Submit
              </button>
            </form>

            {/* Toast Notifications */}
            {submissionSuccess === true && (
              <div className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded shadow-lg">
                {message}
              </div>
            )}
            {submissionSuccess === false && (
              <div className="fixed bottom-4 right-4 bg-red-500 text-white p-4 rounded shadow-lg">
                {message}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
