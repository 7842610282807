import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface Section {
  id: number;
  title: string;
  text: string;
  file?: {
    imageUrl?: string;
  };
}

interface SortableItemProps {
  section: Section;
  onSave: (updatedSection: Section) => void;
  onImageClick: () => void;
  onDelete: () => void;
  onTitleChange: (title: string) => void;
  onTextChange: (text: string) => void;
}

const SortableItem: React.FC<SortableItemProps> = ({
  section,
  onSave,
  onImageClick,
  onDelete,
  onTitleChange,
  onTextChange,
}) => {
  const [editedTitle, setEditedTitle] = useState(section.title);
  const [editedText, setEditedText] = useState(section.text);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: section.id.toString() });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 100 : 1,
    opacity: isDragging ? 0.9 : 1,
    boxShadow: isDragging ? '0 5px 15px rgba(0, 0, 0, 0.2)' : 'none',
    cursor: isDragging ? 'grabbing' : 'grab',
    border: '1px solid #e2e8f0',
  };

  // Add log to track section details and image URL
  //console.log('Rendering SortableItem with section:', section);
  //console.log('Image URL in SortableItem:', section.file?.imageUrl);  // Move log outside of JSX

  const handleSaveClick = () => {
    onSave({ ...section, title: editedTitle, text: editedText });
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="p-2 mb-2 bg-white rounded cursor-pointer transition-all duration-150 ease-in-out"
    >
      <div className="flex justify-between items-center">
        <input
          type="text"
          value={editedTitle}
          onChange={(e) => {
            setEditedTitle(e.target.value);
            onTitleChange(e.target.value);
          }}
          className="text-lg font-bold w-3/4 p-1 border-b-2 focus:outline-none focus:border-blue-500"
        />
        <div {...listeners} {...attributes} className="cursor-grab select-none ml-2">
          &#x2630; {/* Drag handle icon */}
        </div>
      </div>
      <textarea
        className="w-full p-1 border border-gray-300 rounded mb-2 text-sm"
        value={editedText}
        onChange={(e) => {
          setEditedText(e.target.value);
          onTextChange(e.target.value);
        }}
      />
      <div className="flex items-center space-x-2">
        <button onClick={onImageClick} className="bg-blue-500 text-white px-2 py-1 rounded text-sm">
          Edit Image
        </button>
        <button onClick={handleSaveClick} className="bg-green-500 text-white px-2 py-1 rounded text-sm">
          Save
        </button>
        <button onClick={onDelete} className="bg-red-500 text-white px-2 py-1 rounded text-sm">
          Delete
        </button>
      </div>
      {section.file?.imageUrl && (
        <img
          src={section.file.imageUrl}
          alt={section.title}
          className="thumbnail-image"
        />
      )}
    </div>
  );
};

export default SortableItem;
