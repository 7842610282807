import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// Define a simplified interface for handling PDFs
interface PdfItem {
  id: number;
  title: string;
  description?: string;
  file?: {
    pdfUrl?: string;
  };
}

interface SortablePDFItemProps {
  item: PdfItem;
  onSave: (updatedItem: PdfItem) => void;
  onDelete: () => void;
  onTitleChange: (title: string) => void;
  onDescriptionChange?: (description: string) => void;
}

const SortablePDFItem: React.FC<SortablePDFItemProps> = ({
  item,
  onSave,
  onDelete,
  onTitleChange,
  onDescriptionChange,
}) => {
  const [editedTitle, setEditedTitle] = useState(item.title);
  const [editedDescription, setEditedDescription] = useState(item.description || '');

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id.toString() });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 100 : 1,
    opacity: isDragging ? 0.9 : 1,
    boxShadow: isDragging ? '0 5px 15px rgba(0, 0, 0, 0.2)' : 'none',
    cursor: isDragging ? 'grabbing' : 'grab',
    border: '1px solid #e2e8f0',
  };

  const handleSaveClick = () => {
    onSave({ ...item, title: editedTitle, description: editedDescription });
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="p-2 mb-2 bg-white rounded cursor-pointer transition-all duration-150 ease-in-out"
    >
      <div className="flex justify-between items-center">
        <input
          type="text"
          value={editedTitle}
          onChange={(e) => {
            setEditedTitle(e.target.value);
            onTitleChange(e.target.value);
          }}
          className="text-lg font-bold w-3/4 p-1 border-b-2 focus:outline-none focus:border-blue-500"
        />
        <div {...listeners} {...attributes} className="cursor-grab select-none ml-2">
          &#x2630; {/* Drag handle icon */}
        </div>
      </div>
      <textarea
        className="w-full p-1 border border-gray-300 rounded mb-2 text-sm"
        value={editedDescription}
        onChange={(e) => {
          setEditedDescription(e.target.value);
          onDescriptionChange && onDescriptionChange(e.target.value);
        }}
      />
      <div className="flex items-center space-x-2">
        <button onClick={handleSaveClick} className="bg-green-500 text-white px-2 py-1 rounded text-sm">
          Save
        </button>
        <button onClick={onDelete} className="bg-red-500 text-white px-2 py-1 rounded text-sm">
          Delete
        </button>
      </div>
      {item.file?.pdfUrl && (
        <div className="mt-2">
          <a href={item.file.pdfUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
            View PDF
          </a>
        </div>
      )}
    </div>
  );
};

export default SortablePDFItem;
