import React, { useState } from 'react';

type AgeVerificationModalProps = {
  onVerify: () => void;
};

const AgeVerificationModal: React.FC<AgeVerificationModalProps> = ({ onVerify }) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const handleVerification = (isOldEnough: boolean) => {
    if (isOldEnough) {
      localStorage.setItem('ageVerified', 'true');
      onVerify();
    } else {
      alert('You must be 21 or older to enter this site.');
      window.location.href = 'https://dare.org/'; 
    }
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg text-center">
        <h2 className="text-xl font-bold mb-4">Are you 21 years of age or older?</h2>
        <div className="space-x-4">
          <button 
            onClick={() => handleVerification(true)} 
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
          >
            Yes
          </button>
          <button 
            onClick={() => handleVerification(false)} 
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgeVerificationModal;
