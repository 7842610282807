import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement('#root'); // Correct ID for your app's root element

function InstructionModal() {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <button onClick={openModal} className="info-button p-2 bg-blue-500 hover:bg-blue-700 text-white rounded-full shadow-lg text-lg font-bold focus:outline-none focus:ring-2 focus:ring-blue-300">
        ?
        </button>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Instructions Modal"
            style={{
                content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                border: '1px solid #ccc',
                background: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '4px',
                outline: 'none',
                padding: '20px',
                zIndex: '1050', // Ensure it's above other content
                },
                overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                zIndex: '1040', // Ensure it's directly below the modal content
                }
            }}
            >

        <div className="text-lg">
            <h2 className="font-bold mb-4">How to Manage 'About Us' Sections</h2>
            <div>
            <p><strong>Create a New Section:</strong></p>
            <ol className="list-decimal ml-5">
                <li>Click on the "Create New Section" button to start.</li>
                <li>Enter a title and text for the new section in the provided fields.</li>
                <li>Click the "Save Section" button to create the section without an image.</li>
                <li>You can add or edit an image by selecting the newly created section from the list.</li>
            </ol>
            <p><strong>Add or Edit an Image:</strong></p>
            <ol className="list-decimal ml-5">
                <li>Find the section you want to update in the list and click on the "Edit Image" button.</li>
                <li>In the image picker window that appears, select an existing image or upload a new one.</li>
                <li>Once selected, the image will be automatically linked to your section.</li>
            </ol>
            <p><strong>Reorder Sections:</strong></p>
            <ol className="list-decimal ml-5">
                <li>Locate the drag handle next to the section you wish to move.</li>
                <li>Drag the section to its new position in the list.</li>
                <li>Release to drop the section into place. The new order is automatically saved.</li>
            </ol>
            <p><strong>Delete a Section:</strong></p>
            <ol className="list-decimal ml-5">
                <li>Click on the "Delete" button next to the section you want to remove.</li>
                <li>Confirm your action in the prompt to permanently delete the section.</li>
                <li>Note: Deleting a section removes it permanently from the list, but the associated image will remain in storage and can be reused in other sections.</li>
            </ol>
            </div>
            <button onClick={closeModal} className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Close
            </button>
        </div>
        </Modal>

    </div>
  );
}

export default InstructionModal;
