import Layout from '../components/Layout';
import { Link, useNavigate } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';

const Admin = () => {
  const navigate = useNavigate(); 

  const handleSignOut = () => {
    localStorage.removeItem('token'); 
    navigate('/login'); 
  };

  return (
    <Layout>
      <div className="min-h-screen flex flex-col bg-fractal-pattern-3 bg-cover">
        <div className="flex-grow flex flex-col items-center justify-center p-6">
          <h1 className="text-4xl font-extrabold mb-8 text-center text-gray-800">
            Admin Dashboard
          </h1>
          <ErrorBoundary>
            <nav className="w-full max-w-xl space-y-4">
              <Link to="/admin/manage-about-us" className="block w-full bg-blue-400 hover:bg-blue-500 text-gray-900 font-bold py-3 px-4 rounded-lg text-center transition duration-300 ease-in-out">
                Manage About Us
              </Link>
              <Link to="/admin/manage-quotes" className="block w-full bg-indigo-400 hover:bg-indigo-500 text-gray-900 font-bold py-3 px-4 rounded-lg text-center transition duration-300 ease-in-out">
                Manage Quotes
              </Link>
              <Link to="/admin/manage-coa" className="block w-full bg-green-400 hover:bg-green-500 text-gray-900 font-bold py-3 px-4 rounded-lg text-center transition duration-300 ease-in-out">
                Manage COA/Test Results
              </Link>
              <Link to="/admin/contacts" className="block w-full bg-yellow-400 hover:bg-yellow-500 text-gray-900 font-bold py-3 px-4 rounded-lg text-center transition duration-300 ease-in-out">
                View Visitor Contacts
              </Link>
              <Link to="/admin/manage-blog" className="block w-full bg-pink-400 hover:bg-pink-500 text-gray-900 font-bold py-3 px-4 rounded-lg text-center transition duration-300 ease-in-out">
                Manage Blog
              </Link>
              <Link to="/admin/invite-admin" className="block w-full bg-purple-400 hover:bg-purple-500 text-gray-900 font-bold py-3 px-4 rounded-lg text-center transition duration-300 ease-in-out">
                Invite Admin
              </Link>
              <button onClick={handleSignOut} className="w-full bg-red-400 hover:bg-red-500 text-gray-900 font-bold py-3 px-4 rounded-lg mt-8 transition duration-300 ease-in-out">
                Sign Out
              </button>
            </nav>
          </ErrorBoundary>
        </div>
      </div>
    </Layout>
  );
};

export default Admin;
