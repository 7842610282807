import React, { useState } from 'react';
import Layout from '../components/Layout';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token); // Store the token in local storage
        navigate('/admin'); // Redirect to the admin page
      } else {
        setError('Invalid username or password');
      }
    } catch (error) {
      setError('An error occurred while trying to log in');
    }
  };

  return (
    <Layout>
      <div className="flex flex-col min-h-screen bg-fractal-pattern-3 bg-cover">
        <div className="flex-grow flex items-center justify-center p-4">
          <form onSubmit={handleSubmit} className="bg-white p-8 rounded shadow-lg w-full max-w-md">
            <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">Login</h1>
            {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
            <div className="mb-6">
              <label className="block text-gray-700 font-semibold mb-2" htmlFor="username">
                Username
              </label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="border rounded-lg p-3 w-full focus:outline-none focus:border-blue-400"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 font-semibold mb-2" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border rounded-lg p-3 w-full focus:outline-none focus:border-blue-400"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 rounded-lg transition duration-300"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
